import React from 'react'

const PlusIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="9.937" height="9.937" viewBox="0 0 9.937 9.937">
	  <g id="sml-close" transform="translate(4.95 0.745) rotate(45)">
	    <path id="Path_647" data-name="Path 647" d="M3.531,3,5.89.642A.375.375,0,0,0,5.36.111L3,2.47.641.111A.375.375,0,0,0,.11.642L2.47,3,.11,5.361a.375.375,0,1,0,.531.53L3,3.532l2.36,2.36A.375.375,0,0,0,5.89,5.36Z" fill={props.fill} stroke={props.fill} strokeWidth="1"/>
	  </g>
	</svg>
)

export default PlusIcon