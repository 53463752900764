import React from "react"
import { Link } from "gatsby"

import { RedLink } from "../../Link"
import ArrowIcon from "../../Icons/ArrowIcon"
import { Divider } from "../../Section"
import { DropWrapper, Left, DropItem, Right, Triangle } from "./tags-component"

class CarportsDropdown extends React.Component {
  render() {
    return (
      <DropWrapper>
        <Left>
          <Triangle />
          <DropItem>
            <Link to="/carports/vertical-roof/">
              Vertical Roof Carports <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
          <Divider />
          <DropItem>
            <Link to="/carports/a-frame-roof/">
              A-Frame Roof Carports <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
          <Divider />
          <DropItem>
            <Link to="/carports/regular-roof/">
              Regular Roof Carports <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
          <Divider />
          <DropItem>
            <Link to="/metal-carport-prices/">
              Metal Carports Prices <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
          <Divider />
          <DropItem>
            <Link to="/carports/metal-carport-kits/">
            Metal Carport Kits <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
        </Left>
        <Right width="310px">
          <h3>Carports</h3>
          <p>
            Carports offer versatility and convenience. Carports are ideal for
            protecting your cars, trucks, boats, RVs, ATVs, and much more.
          </p>
          <RedLink to="/carports/">
            All Carports
            <ArrowIcon fill="#ED602B" />
          </RedLink>
        </Right>
      </DropWrapper>
    )
  }
}

export default CarportsDropdown
