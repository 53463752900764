import React, { Fragment, useState } from "react"
import styled from "styled-components"
import SearchIcon from "../Icons/SearchIcon"
import { navigate } from "gatsby"
import { DropSearch, DropSearchItem } from "../Section"
import { useSearch } from "../../utils/useSearch"
import { getProductUrl } from "../../utils/product"

const SearchForm = styled.div`
  background-color: #eef8ff;
  border-radius: 6px;
  width: 100%;
  max-width: 360px;
  form {
    position: relative;
    margin-bottom: 2px;

    input {
      width: 100%;
      border: 1px solid #dce7ef;
      border-radius: 6px;
      padding: 2px 5px;
      padding-right: 22px;
      &::placeholder {
        color: #004485;
      }
      &:focus,
      &:active {
        &::placeholder {
          color: #004485;
        }
      }
    }

    button {
      position: absolute;
      border: 0;
      outline: 0;
      background: none;
      padding-top: 3px;
      right: 4px;
      top: 1px;
      display: block;
      svg {
        fill: gray;
        width: 16px;
        height: 16px;
      }
    }
  }
`

const HeaderSearchBox = () => {
  const [searchKey, setSearchKey] = useState("")
  const results = useSearch(searchKey)

  const handleChange = e => {
    e.preventDefault()
    setSearchKey(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()

    navigate("/search", { state: { searchKey: searchKey } })
  }

  const getLinkUrl = item => {
    if (item.type === "tag") {
      navigate("/shop-metal-buildings/", {
        state: { tag: item.title },
      })
    } else {
      navigate(getProductUrl(item))
    }
  }

  const getSearchItemTitle = item => {
    if (item.type === "tag") return item.title
    if (searchKey === item.skuNumber.toString()) return item.skuNumber
    return item.title
  }

  return (
    <Fragment>
      <SearchForm className="search-form">
        <form onSubmit={handleSubmit}>
          <input
            className="form-control"
            type="text"
            placeholder="Search..."
            id="search"
            name="search"
            autoComplete="off"
            onChange={e => handleChange(e)}
          />
          <button type="submit" aria-label="Search">
            <SearchIcon />
          </button>
        </form>
      </SearchForm>
      <DropSearch className="drop-search">
        {results.map((rItem, index) => (
          <DropSearchItem
            className="drop-search-item"
            key={index}
            onClick={() => getLinkUrl(rItem)}
          >
            {getSearchItemTitle(rItem)}
          </DropSearchItem>
        ))}
      </DropSearch>
    </Fragment>
  )
}

export default HeaderSearchBox
