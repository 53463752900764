import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, navigate } from "gatsby"
import styled from "styled-components"
import { Context } from "./context.js"
import Header from "./Header"
import Footer from "./Footer"
import "./layout.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import ChevronDownIcon from "./Icons/ChevronDownIcon.js"
import CompareIcon from "./Icons/CompareIcon.js"
import CloseThinIcon from "./Icons/CloseThinIcon.js"
import Img from "gatsby-image"
import ProductSelectorModal from "./ProductSelectorModal/index.js"

const LayoutWrap = styled.div`
  position: relative;
`
const ScrollToTopBtn = styled.button`
  position: fixed;
  z-index: 99;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #062c44;
  border: none;
  color: #fff;
  border: 1px solid #eee;

  width: 30px;
  height: 30px;
  bottom: 80px;
  right: 15px;
  @media (min-width: 768px) {
    bottom: 60px;
    right: 30px;
    width: 40px;
    height: 40px;
  }
`

const ComparisonSticky = styled.div`
  position: fixed;
  z-index: 99;
  bottom: 68px;
  left: 15px;
  @media (min-width: 768px) {
    left: auto;
    right: 80px;
    bottom: 10px;
  }
`
const StickyContainer = styled.div``
const StickyHeader = styled.div`
  align-items: center;
  background: linear-gradient(134deg, #ff7245, #ff3e00);
  border-radius: 20px 20px 0;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);
  color: #fff;
  cursor: pointer;
  padding: 8px 15px;
  display: inline-flex;
  @media (min-width: 768px) {
    display: flex;
  }
  .icon {
    > svg {
      fill: #fff;
    }
    + .title {
      margin-left: 5px;
    }
  }
  .title-pb {
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 3px;
    padding-right: 5px;
    transition: transform 0.2s;
  }
  .title {
    padding-left: 5px;
    padding-right: 15px;
    @media (max-width: 575.98px) {
      display: none;
    }
  }
  .title-mobile {
    padding-left: 5px;
    @media (min-width: 575.98px) {
      display: none;
    }
  }
  .sticky-toggle-arrow {
    margin-left: auto;
    @media (max-width: 575.98px) {
      display: none;
    }
  }
`
const StickyBody = styled.div`
  height: ${props => (props.collapsed ? 0 : props.height)}px;
  background-color: #fff;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);
  margin-left: 20px;
  overflow: hidden;
  transition: height 0.3s;
  border-radius: 0 20px 20px 20px;
  @media (min-width: 768px) {
    border-radius: 0 0 20px 20px;
  }
`
const StickyContent = styled.ul`
  box-shadow: inset 0 0 4px 1px rgba(0, 0, 0, 0.1);
  list-style: none;
  margin: 0;
  max-height: 180px;
  overflow-y: auto;
  padding: 0;
  ::-webkit-scrollbar {
    width: 3px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`
const ComparisonItem = styled.li`
  display: flex;
  align-items: center;
  margin: 0 15px;
  padding: 7px 0;
  border-bottom: 1px solid #dfdfdf;
  .product-image {
    width: 45px;
    height: 30px;
    margin-right: 5px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .product-title {
    width: 140px;
    color: #000;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
  }
  .btn-remove {
    cursor: pointer;
    width: 22px;
    height: 22px;
    svg {
      width: 18px;
      height: 18px;
    }
  }
`
const ComparisonActions = styled.div`
  position: relative;
`
const ComparisonInnderActions = styled.div`
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .comparison-action {
    background-color: #062c44;
    border: 1px solid #062c44;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    height: 30px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .compare-btn {
    padding: 8px 32px;
  }
  .compare-plus {
    width: 30px;
  }
`

const defaultCompareList = [null, null, null, null]

const Layout = ({ children, location, isShowProduct, hideSearch = false }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [comparisonData, setComparisonData] = useState(defaultCompareList)
  const [compareProducts, setCompareProducts] = useState([])
  const [compareStickyOpen, setCompareStickyOpen] = useState(false)
  const [selectProductInfo, setSelectProductInfo] = useState({
    isVisible: false,
    onSelect: null,
    onToggle: null,
  })
  const isComparePage = location.pathname === "/compare" || location.pathname.startsWith("/compare/");

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const cd = localStorage.getItem("comparisonData");
      setComparisonData(cd ? JSON.parse(cd) : defaultCompareList);
    }
  }, [])

  useEffect(() => {
    if (isShowProduct) {
      document.body.classList.add("crm-product")
    } else {
      document.body.classList.remove("crm-product")
    }
  }, [isShowProduct])

  useEffect(() => {
    localStorage.setItem("comparisonData", JSON.stringify(comparisonData))
    const ps = comparisonData.filter(p => p)
    setCompareProducts(ps)
  }, [comparisonData])

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility)
    return () => {
      window.removeEventListener("scroll", toggleVisibility)
    }
  }, [])

  const getStickyTitle = () => {
    if (compareStickyOpen) {
      return "Comparison List"
    }
    if (compareProducts.length === 1) return "1 item selected"
    return `${compareProducts.length} items selected`
  }
  const toggleComparisonSticky = () => {
    setCompareStickyOpen(!compareStickyOpen)
  }
  const getStickyBodyHeight = () => {
    if (compareProducts.length >= 4) return 180 + 40
    return compareProducts.length * 45 + 40
  }

  const removeProduct = productId => {
    const pId = comparisonData.findIndex(p => p && p.id === productId)
    const ncd = [...comparisonData]
    ncd[pId] = null
    setComparisonData(ncd)
  }

  const addProductToComparison = (type, product, no) => {
    if (!comparisonData || !comparisonData.length) {
      if (no === undefined) {
        setComparisonData([product, null, null, null]);
      } else {
        const pc = new Array(4).fill(null);
        pc[no] = product;
        setComparisonData(pc);
      }
      if (isComparePage && type === "card") {
        alert("Product added to the comparison list");
      }
      return;
    }

    const newData = [...comparisonData];
    let found = newData.findIndex((item) => item && item.id === product.id);
    if (found >= 0) {
      newData[found] = product;
      setComparisonData(newData);
      alert("Product already added!");
      return;
    }

    if (no >= 0) {
      const pc = [...comparisonData];
      pc[no] = product;
      setComparisonData(pc);
      if (isComparePage && type === "card") {
        alert("Product added to the comparison list");
      }
    } else {
      const newData = [...comparisonData];
      const k = newData.findIndex((item) => item === null);
      if (k === -1) {
        if (newData.length >= 4) {
          alert("You cannot add more than 4 products to compare");
          return;
        }
        newData.push(product);
      } else {
        newData[k] = product;
      }
      setComparisonData(newData);
      if (isComparePage && type === "card") {
        alert("Product added to the comparison list");
      }
    }
  };

  const selectProduct = () => {
    return new Promise((resolve, reject) => {
      setSelectProductInfo({
        onSelect: p => {
          setSelectProductInfo({
            isVisible: false,
            onSelect: null,
            onToggle: null,
          })
          resolve(p)
        },
        onToggle: p => {
          setSelectProductInfo({
            isVisible: false,
            onSelect: null,
            onToggle: null,
          })
          reject(p)
        },
        isVisible: true,
      })
    })
  }

  return (
    <Context.Provider
      value={{
        comparisonData,
        compareProducts,
        addProductToComparison,
        removeProduct,
        selectProduct,
      }}
    >
      <LayoutWrap>
        <Header
          siteTitle={data.site.siteMetadata.title}
          hideSearch={hideSearch}
        />
        <main>{children}</main>
        <Footer location={location} />

        {isVisible && (
          <ScrollToTopBtn onClick={scrollToTop}> ↑ </ScrollToTopBtn>
        )}

        {!!compareProducts && compareProducts.length > 0 && !isComparePage && (
          <ComparisonSticky>
            <StickyContainer>
              <StickyHeader onClick={() => toggleComparisonSticky()}>
                <span className="icon">
                  <CompareIcon />
                </span>
                <span className="title">{getStickyTitle()}</span>
                <span className="title-mobile">({compareProducts.length})</span>
                <div className="sticky-toggle-arrow">
                  <ChevronDownIcon width={16} height={16} color="white" />
                </div>
              </StickyHeader>
              <StickyBody
                collapsed={!compareStickyOpen}
                height={getStickyBodyHeight()}
              >
                <StickyContent>
                  {compareProducts.map((product, i) => (
                    <ComparisonItem key={i}>
                      <div className="product-image">
                        {product.image ? (
                          <Img
                            fluid={product.image.fluid}
                            alt={product.title}
                          />
                        ) : (
                          <img src={product.imageSrc} alt={product.title} />
                        )}
                      </div>
                      <div className="product-title">{product.title}</div>
                      <div
                        className="btn-remove"
                        onClick={() => removeProduct(product.id)}
                      >
                        <CloseThinIcon fill="black" />
                      </div>
                    </ComparisonItem>
                  ))}
                </StickyContent>
                <ComparisonActions>
                  <ComparisonInnderActions>
                    <div
                      className="comparison-action compare-btn"
                      onClick={() => {
                        if (compareProducts.length < 2) {
                          alert("Select one more product to compare")
                          return
                        }
                        navigate("/compare/")
                      }}
                    >
                      Compare
                    </div>
                    <div
                      className="comparison-action compare-plus"
                      onClick={async () => {
                        const p = await selectProduct()
                        addProductToComparison("select", p)
                      }}
                    >
                      +
                    </div>
                  </ComparisonInnderActions>
                </ComparisonActions>
              </StickyBody>
            </StickyContainer>
          </ComparisonSticky>
        )}

        <ProductSelectorModal
          isVisible={selectProductInfo.isVisible}
          onToggle={selectProductInfo.onToggle || null}
          onSelect={selectProductInfo.onSelect || null}
        />
      </LayoutWrap>
    </Context.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
