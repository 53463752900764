import styled from "styled-components"
import { ModalBody } from "../Modal"

export const PSelectorModalBody = styled(ModalBody)`
  padding-top: 12px;
  padding-left: 1rem;
  padding-right: 4rem;
  padding-bottom: 2rem;
  min-height: 330px;
`

export const SearchWrap = styled.div`
  position: relative;
`

export const SearchInput = styled.div`
  width: 100%;
  padding: 9px 25px;
  background-color: #fff;
  box-shadow: 0 8px 25px rgba(11, 97, 155, 0.1);
  height: 48px;
  box-sizing: border-box;
  z-index: 1;
  border: 1px solid #afcfe5;
  border-radius: 100px;

  .input-icon {
    height: 30px;
  }

  .form-control {
    height: 30px;
  }
  .icon {
    svg {
      width: 20px;
      height: 20px;
      fill: #000;
    }
  }
`

export const ProductListWrapper = styled.div`
  overflow: auto;
  max-height: 512px;
  margin-top: 2rem;
  padding-left: 2rem;
`

export const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-right: 1rem;
`

export const ProductItem = styled.div`
  cursor: pointer;
  border-bottom: 1px solid rgb(227, 233, 242);
  &:hover {
    background: #f2f2ff;
  }
`
