import React from "react"

const CloseThinIcon = (props) => (
  <svg
    fill="#000000"
    width="26"
    height="26"
    viewBox="0 0 50 50"
  >
    <path d="M37.304 11.282l1.414 1.414-26.022 26.02-1.414-1.413z" />
    <path d="M12.696 11.282l26.022 26.02-1.414 1.415-26.022-26.02z" />
  </svg>
)

export default CloseThinIcon
