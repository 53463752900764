import React from "react"
import { Link } from "gatsby"

import { RedLink } from "../../Link"
import ArrowIcon from "../../Icons/ArrowIcon"
import { Divider } from "../../Section"
import { DropWrapper, Left, DropItem, Right, Triangle } from "./tags-component"

class BarnsDropdown extends React.Component {
  render() {
    return (
      <DropWrapper>
        <Left width="300px" ml="-100px" mt="10px">
          <Triangle left="55%" />
          <DropItem>
            <Link to="/barns/vertical-roof/">
              Vertical Roof Barns <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
          <Divider />
          <DropItem>
            <Link to="/barns/a-frame-roof/">
              A-Frame Roof Barns <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
          <Divider />
          <DropItem>
            <Link to="/barns/regular-roof/">
              Regular Roof Barns <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
          <Divider />
          <DropItem>
            <Link to="/barns/seneca-barns/">
              Seneca Barns <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
          <Divider />
          <DropItem>
            <Link to="/barns/carolina-barns/">
              Carolina Barns <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
          <Divider />
          <DropItem>
            <Link to="/metal-barn-prices/">
              Metal Barns Prices <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
          <Divider />
          <DropItem>
            <Link to="/barns/metal-barn-kits/">
            Metal Barn Kits <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
        </Left>
        <Right width="350px">
          <h3>Barns</h3>
          <p>
            Steel barns offer versatility and convenience. Steel barns are ideal
            for storing farm equipment, livestock, hay, agricultural machinery
            and much more.
          </p>
          <RedLink to="/barns/">
            All Barns
            <ArrowIcon fill="#ED602B" />
          </RedLink>
        </Right>
      </DropWrapper>
    )
  }
}

export default BarnsDropdown
