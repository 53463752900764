import React from "react"
import { Link } from "gatsby"

import { RedLink } from "../../Link"
import ArrowIcon from "../../Icons/ArrowIcon"
import { Divider } from "../../Section"
import { DropWrapper, Left, DropItem, Right, Triangle } from "./tags-component"

class RVCoversDropdown extends React.Component {
  render() {
    return (
      <DropWrapper>
        <Left width="350px">
          <Triangle />
          <DropItem>
            <Link to="/rv-covers/vertical-roof/">
              Vertical Roof RV Covers <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
          <Divider />
          <DropItem>
            <Link to="/rv-covers/a-frame-roof/">
              A-Frame Roof RV Covers <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
          <Divider />
          <DropItem>
            <Link to="/rv-covers/regular-roof/">
              Regular Roof RV Covers <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
          <Divider />
          <DropItem>
            <Link to="/rv-covers/rv-garages/">
            RV Garages <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
        </Left>
        <Right width="330px">
          <h3>RV Covers</h3>
          <p>
            RV Covers offer protection and peace of mind for your recreational
            investment. RV Covers are ideal for protecting and storing your RV
            and other valuables.
          </p>
          <RedLink to="/rv-covers/">
            All RV Covers
            <ArrowIcon fill="#ED602B" />
          </RedLink>
        </Right>
      </DropWrapper>
    )
  }
}

export default RVCoversDropdown
