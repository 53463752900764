import React, { Component } from "react"
import { Link } from "gatsby"
import { RedLink } from "../../Link"
import PlusIcon from "../../Icons/PlusIcon"
import {
  DropWrapp,
  DropButton,
  DropButtonText,
  DropButtonImg,
  FadeContent,
} from "./style"

class GaragesMobileNav extends Component {
  render() {
    const { isVisible } = this.props
    return (
      <DropWrapp isVisible={isVisible}>
        <DropButton onClick={this.props.open} isVisible={isVisible}>
          <DropButtonText>Garages</DropButtonText>
          <DropButtonImg isVisible={isVisible}>
            <PlusIcon fill="#1E4156" />
          </DropButtonImg>
        </DropButton>
        <FadeContent isVisible={isVisible} onClick={this.props.onChange}>
          <Link to="/garages/vertical-roof/">
            Vertical Roof Garages
          </Link>
          <Link to="/garages/a-frame-roof/">A-Frame Roof Garages </Link>
          <Link to="/garages/regular-roof/">Regular Roof Garages </Link>
          <Link to="/metal-garage-prices/">Metal Garages Prices </Link>
          <Link to="/garages/metal-garage-kits/">Metal Garages Kits </Link>
          <RedLink to="/garages/">All Garages</RedLink>
        </FadeContent>
      </DropWrapp>
    )
  }
}

export default GaragesMobileNav
