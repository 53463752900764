import React, { Component } from "react"
import { Link } from "gatsby"
import { RedLink } from "../../Link"
import PlusIcon from "../../Icons/PlusIcon"
import {
  DropWrapp,
  DropButton,
  DropButtonText,
  DropButtonImg,
  FadeContent,
} from "./style"

class RvCoversMobileNav extends Component {
  render() {
    const { isVisible } = this.props
    return (
      <DropWrapp isVisible={isVisible}>
        <DropButton onClick={this.props.open} isVisible={isVisible}>
          <DropButtonText>RV Covers</DropButtonText>
          <DropButtonImg
            transform={isVisible ? "rotate(45deg)" : "rotate(90deg)"}
          >
            <PlusIcon fill="#1E4156" />
          </DropButtonImg>
        </DropButton>
        <FadeContent isVisible={isVisible} onClick={this.props.onChange}>
          <Link to="/rv-covers/vertical-roof/">
            Vertical Roof RV Covers
          </Link>
          <Link to="/rv-covers/a-frame-roof/">
            A-Frame Roof RV Covers
          </Link>
          <Link to="/rv-covers/regular-roof/">
            Regular Roof RV Covers
          </Link>
            <Link to="/rv-covers/rv-garages/">
            RV Garages
            </Link>
          <RedLink to="/rv-covers/">All RV Covers</RedLink>
        </FadeContent>
      </DropWrapp>
    )
  }
}

export default RvCoversMobileNav
