import styled, { keyframes }  from 'styled-components'

export const dropdown = keyframes`
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`

export const DropWrapper = styled.div`
	max-width: 750px;
	position: absolute;
	margin-top: 8px;
	display: grid;
	align-items: center;
	grid-template-columns: repeat(2, auto);
	width: 0px;
	height: 0px;
	animation: ${dropdown} ease 0.5s;
`
export const SingleDropWrapper = styled.div`
	max-width: 800px;
	position: absolute;
	margin-top: 20px;
	animation: ${dropdown} ease 0.5s;
	display: grid;
	grid-template-columns: repeat(2, auto);
	grid-column-gap: 20px;
	padding: 50px 70px;
	background: white;
	border-radius: 10px;
	margin-left: ${props=>props.ml};
	text-align: left;
	box-shadow: 0px 25px 65px rgba(1, 9, 32, 0.2);
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	
	& svg {
		transform: none !important;
		float: right;
		margin-top: 8px;
		margin-left: 10px;
		margin-right: 5px;
		transition: ease 0.3s;
	}
`
export const Left = styled.div`
	background: white;
	z-index: 98;
	margin-right: -10px;
	padding: 50px 40px;
	border-radius: 10px;
	box-shadow: 0px 25px 65px rgba(1, 9, 32, 0.2);
	width: ${props=>props.width};
	margin-left: ${props=>props.ml};
	margin-top: ${props=>props.mt};
	text-align: left;
	& svg {
		float: right;
		margin-top: 9px;
		transform: none !important;
		transition: ease 0.3s;
	}
`
Left.defaultProps = {
	width: "330px"
}

export const DropItem = styled.div`
	font-size: 20px;
	line-height: 24px;
	font-weight: 800;
	color: #1E4156;

	& a {
		display:block;
		color: #1E4156;
		text-decoration: none;
	}
	
	& :hover {
		cursor: pointer;
		& a { 
			color: #ED602B;	
		}
		& svg {
			margin-right: -5px;
		}
	}
`
export const Right = styled.div`
	background: #F4FBFF;
	z-index: 99;
	margin-left: ${props=>props.ml};
	margin-right: ${props=>props.mr};
	width: ${props=>props.width};
	padding: 50px 35px;
	box-shadow: 0px 25px 65px rgba(1, 9, 32, 0.2);
	border-radius: 10px;
	text-align: left;
	& h3 {
		font-size: 34px;
		line-height: 41px;
		text-align: left;
	}
	& p {
		text-align: left;
		color: #676B79;
		font-size: 14px;
		line-height: 22px;
		margin: 11px 0;
	}
	& a{
		display: inline-block;
	}
`
Right.defaultProps = {
	ml: "-10px",
}
export const Triangle = styled.div`
  position: relative;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0px 25px 65px rgba(1, 9, 32, 0.2);

  & :after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    margin-top: -49px;
    left: ${props=>props.left};
    box-sizing: border-box;
    border: 0.5em solid #fff;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -3px 3px 4px -2px rgba(0,0,0,0.1)
  }
`
Triangle.defaultProps = {
	left: "15%"
}