import React, { Component } from "react"
import { Link } from "gatsby"
import { RedLink } from "../../Link"
import PlusIcon from "../../Icons/PlusIcon"
import {
  DropWrapp,
  DropButton,
  DropButtonText,
  DropButtonImg,
  FadeContent,
} from "./style"

class CarportsMobileNav extends Component {
  render() {
    const { isVisible } = this.props
    return (
      <DropWrapp isVisible={isVisible}>
        <DropButton onClick={this.props.open} isVisible={isVisible}>
          <DropButtonText>Carports</DropButtonText>
          <DropButtonImg isVisible={isVisible}>
            <PlusIcon fill="#1E4156" />
          </DropButtonImg>
        </DropButton>
        <FadeContent isVisible={isVisible} onClick={this.props.onChange}>
          <Link to="/carports/vertical-roof/">
            Vertical Roof Carports{" "}
          </Link>
          <Link to="/carports/a-frame-roof/">
            A-Frame Roof Carports{" "}
          </Link>
          <Link to="/carports/regular-roof/">
            Regular Roof Carports{" "}
          </Link>
          <Link to="/metal-carport-prices/">Metal Carports Prices </Link>
          <Link to="/carports/metal-carport-kits/">
            Metal Carport Kits 
          </Link>
          <RedLink to="/carports/">All Carports</RedLink>
        </FadeContent>
      </DropWrapp>
    )
  }
}

export default CarportsMobileNav
