import React from "react"
import { Link } from "gatsby"

import { RedLink } from "../../Link"
import ArrowIcon from "../../Icons/ArrowIcon"
import { Divider } from "../../Section"
import { DropWrapper, Left, DropItem, Right, Triangle } from "./tags-component"

class GaragesDropdown extends React.Component {
  render() {
    return (
      <DropWrapper>
        <Left>
          <Triangle />
          <DropItem>
            <Link to="/garages/vertical-roof/">
              Vertical Roof Garages <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
          <Divider />
          <DropItem>
            <Link to="/garages/a-frame-roof/">
              A-Frame Roof Garages <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
          <Divider />
          <DropItem>
            <Link to="/garages/regular-roof/">
              Regular Roof Garages <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
          <Divider />
          <DropItem>
            <Link to="/metal-garage-prices/">
              Metal Garages Prices <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
          <Divider />
          <DropItem>
            <Link to="/garages/metal-garage-kits/">
              Metal Garages Kits <ArrowIcon fill="#ED602B" />
            </Link>
          </DropItem>
        </Left>
        <Right width="300px">
          <h3>Garages</h3>
          <p>
            Garages offer protection and peace of mind. Garages are ideal for
            protecting your cars, trucks, and other valuables.
          </p>
          <RedLink to="/garages/">
            All Garages
            <ArrowIcon fill="#ED602B" />
          </RedLink>
        </Right>
      </DropWrapper>
    )
  }
}

export default GaragesDropdown
