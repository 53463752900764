import React from 'react'

const CloseIcon = (props)=>(
	<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M9.66198 1.96964L6.63204 4.99988L9.66198 8.02999C10.1127 8.48085 10.1127 9.21118 9.66198 9.66204C9.4368 9.88723 9.14156 9.99991 8.84647 9.99991C8.55089 9.99991 8.25563 9.8874 8.03062 9.66204L5.00001 6.63159L1.96964 9.66201C1.74449 9.8872 1.44922 9.99988 1.15387 9.99988C0.85861 9.99988 0.563548 9.88737 0.338189 9.66201C-0.1125 9.21135 -0.1125 8.48099 0.338189 8.02996L3.36804 4.99986L0.338017 1.96964C-0.112672 1.51895 -0.112672 0.788445 0.338017 0.337757C0.788619 -0.112586 1.5187 -0.112586 1.96947 0.337757L4.99999 3.36801L8.03027 0.337757C8.48113 -0.112586 9.2113 -0.112586 9.66181 0.337757C10.1127 0.788445 10.1127 1.51895 9.66198 1.96964Z" fill={props.fill}/>
	</svg>
	
)

export default CloseIcon
