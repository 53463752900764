import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { RedLink } from "../../Link"
import ArrowIcon from "../../Icons/ArrowIcon"
import { Divider } from "../../Section"
import {
  SingleDropWrapper,
  DropItem,
  Triangle,
  dropdown,
} from "./tags-component"

const ButtomNav = styled.div`
  position: absolute;
  width: 540px;
  padding: 25px 70px;
  text-align: left;
  margin-left: -415px;
  top: 335px;
  background: #f6f7f8;
  border: 1px solid rgba(1, 9, 32, 0.1);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  animation: ${dropdown} ease 0.4s;
  & svg {
    transform: none !important;
  }
`

class AboutDropdown extends React.Component {
  render() {
    return (
      <div style={{ position: "absolute" }}>
        <SingleDropWrapper ml="-415px">
          <div style={{ width: "230px" }}>
            <DropItem>
              <Link to="/service-area/">
                Service Area Map <ArrowIcon fill="#ED602B" />
              </Link>
            </DropItem>
            <Divider />
            <DropItem>
              <Link to="/contact-us/">
                Contact Us <ArrowIcon fill="#ED602B" />
              </Link>
            </DropItem>
            <Divider />
            <DropItem>
              <Link to="/infographics/">
                Infographic <ArrowIcon fill="#ED602B" />
              </Link>
            </DropItem>
            <Divider />
            <DropItem>
              <Link to="/case-studies/">
              Case Studies <ArrowIcon fill="#ED602B" />
              </Link>
            </DropItem>
          </div>
          <div style={{ width: "150px" }}>
            <Triangle left="90%" />
            <DropItem>
              <Link to="/faqs/">
                FAQ’s <ArrowIcon fill="#ED602B" />
              </Link>
            </DropItem>
            <Divider />
            <DropItem>
              <Link to="/blog/">
                Blog <ArrowIcon fill="#ED602B" />
              </Link>
            </DropItem>
            <Divider />
            <DropItem>
              <Link to="/reviews/">
                Reviews <ArrowIcon fill="#ED602B" />
              </Link>
            </DropItem>
            <Divider />            
          </div>
        </SingleDropWrapper>
        <ButtomNav>
          <RedLink to="/about/">
            About Probuilt <ArrowIcon fill="#ED602B" />
          </RedLink>
        </ButtomNav>
      </div>
    )
  }
}

export default AboutDropdown
