import React, { Component } from "react"
import { Link } from "gatsby"
import { RedLink } from "../../Link"
import PlusIcon from "../../Icons/PlusIcon"
import {
  DropWrapp,
  DropButton,
  DropButtonText,
  DropButtonImg,
  FadeContent,
} from "./style"

class BuyingGuideMobileNav extends Component {
  render() {
    const { isVisible } = this.props
    return (
      <DropWrapp isVisible={isVisible}>
        <DropButton onClick={this.props.open} isVisible={isVisible}>
          <DropButtonText>Buying Guide</DropButtonText>
          <DropButtonImg isVisible={isVisible}>
            <PlusIcon fill="#1E4156" />
          </DropButtonImg>
        </DropButton>
        <FadeContent isVisible={isVisible} onClick={this.props.onChange}>
          <Link to="/buying-guide/steel-building-components/">
            Steel Building Components{" "}
          </Link>
          <Link to="/buying-guide/metal-building-roof-guide/">
            Metal Building Roof Guide{" "}
          </Link>
          <Link to="/buying-guide/site-preparation-guide/">
            Site Preparation Guide{" "}
          </Link>
          <Link to="/buying-guide/order-installation-process/">
            Order/Installation Process{" "}
          </Link>
          <Link to="/buying-guide/permit-engineering-guide/">
            Permit & Engineering Guide{" "}
          </Link>
          <Link to="/buying-guide/financing-process/">Financing Process </Link>
          <Link to="/buying-guide/rto-process/">RTO Process </Link>
          <RedLink to="/buying-guide/">Buying Guide</RedLink>
        </FadeContent>
      </DropWrapp>
    )
  }
}

export default BuyingGuideMobileNav
