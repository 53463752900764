import styled from 'styled-components'
import { Link } from 'gatsby'

export const RedLink = styled(Link)`
	color: #ED602B !important;
	font-size: 16px;
	font-weight: 800;
	line-height: 26px;
	text-decoration: none;
	transition: ease 0.5s;
	& svg {
		margin-left: 10px;
		transform: none !important;
		transition: ease 0.3s;
		width: 25px;
	}
	& :hover {
		text-decoration: underline;
		& svg {
			margin-left: 20px;
		}
	}
`