import React from 'react'

const ArrowIcon = (props)=>(
	<svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="12" viewBox="0 0 31.5 12">
	  <g transform="translate(-710 -971)">
	    <path d="M5.429,19.451A.83.83,0,0,0,6,19.687a.805.805,0,0,0,.574-.236l5.183-5.065a.782.782,0,0,0,0-1.128.826.826,0,0,0-1.154,0L6.819,16.966V.8A.816.816,0,0,0,5.188.8V16.966L1.394,13.259a.826.826,0,0,0-1.154,0,.782.782,0,0,0,0,1.128Z" transform="translate(710 983) rotate(-90)" fill={props.fill}/>
	    <path d="M5.429,6.427A.83.83,0,0,0,6,6.663a.805.805,0,0,0,.574-.236L11.76,1.362a.782.782,0,0,0,0-1.128.826.826,0,0,0-1.154,0L6.819,3.942A1.366,1.366,0,0,1,6,4.316a1.3,1.3,0,0,1-.816-.374L1.394.235A.826.826,0,0,0,.24.235a.782.782,0,0,0,0,1.128Z" transform="translate(728.93 983) rotate(-90)" fill={props.fill} opacity="0.4"/>
	    <path d="M5.429,6.427A.83.83,0,0,0,6,6.663a.805.805,0,0,0,.574-.236L11.76,1.362a.782.782,0,0,0,0-1.128.826.826,0,0,0-1.154,0L6.819,3.942A1.366,1.366,0,0,1,6,4.316a1.3,1.3,0,0,1-.816-.374L1.394.235A.826.826,0,0,0,.24.235a.782.782,0,0,0,0,1.128Z" transform="translate(734.836 983) rotate(-90)" fill={props.fill} opacity="0.2"/>
	  </g>
	</svg>
)

export default ArrowIcon
