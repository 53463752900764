import styled from "styled-components"

export const Modal = styled.div`
  display: none;
  @warn ${props => props.display};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
`
Modal.defaultProps = {}
export const CloseButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  @media (min-width: 992px) {
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
  }
  svg {
    fill: #000;
  }
  &:hover {
    svg {
      fill: #ff3e00;
    }
  }
`
CloseButton.defaultProps = {}

export const ModalDialog = styled.div`
  position: relative;
  width: auto;
  pointer-events: none;
`
ModalDialog.defaultProps = {}

export const ModalHeader = styled.div`
  position: relative;
  padding: 1rem 20px 20px;
  @media (min-width: 768px) {
    padding: 1rem 30px 30px;
  }
  @media (min-width: 992px) {
    padding: 1rem 60px;
  }
`

export const ModalContent = styled.div`
  background-color: ${props => props.bgColor};
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0;
`
ModalContent.defaultProps = {
  bgColor: "#fff",
}

export const ModalBody = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 40px 20px 20px;
  @media (min-width: 768px) {
    padding: 60px 30px 30px;
  }
  @media (min-width: 992px) {
    padding: 60px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    color: #062c44;
  }
`
ModalBody.defaultProps = {}

export const ModalTitle = styled.div`
  font-family: "Chakra Petch";
  font-weight: 700;
  margin: 0 0 15px;
  text-align: ${props => props.textAlign};
  font-size: 20px;
  line-height: 30px;
  color: #062c44;
  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 34px;
  }
  @media (min-width: 992px) {
    font-size: 28px;
    line-height: 38px;
  }
`
ModalTitle.defaultProps = {
  textAlign: "center",
}

export const ModalFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
`
ModalFooter.defaultProps = {}
export const ModalToolbar = styled.div`
  display: flex;
  flex-wrap: wrap;
  .btn {
  }
`
ModalToolbar.defaultProps = {}

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  inset: 0px;
  &.fade {
    opacity: 0;
  }
  &.show {
    opacity: 0.8;
  }
`
