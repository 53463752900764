import React from "react"
import styled from "styled-components"

const Embed = styled.div`  
 max-height:180px;
 border:2px solid rgba(255, 255, 255, 0.5);
`

const Map = () => {
  return (
    <Embed className="embed-responsive embed-responsive-16by9">
      <iframe className="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3449.031488423887!2d-82.69655172421606!3d30.179094474856115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88ef3a49e5fffba1%3A0xff8159e1ba632d80!2sProBuilt%20Steel%20Buildings!5e0!3m2!1sen!2sus!4v1692359816407!5m2!1sen!2sus" allowFullScreen="" loading="lazy" title="map" referrerPolicy="no-referrer-when-downgrade"></iframe>
    </Embed>
  )
}

export default Map