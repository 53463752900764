import React, { Component } from "react"
import { Link } from "gatsby"
import { RedLink } from "../../Link"
import PlusIcon from "../../Icons/PlusIcon"
import {
  DropWrapp,
  DropButton,
  DropButtonText,
  DropButtonImg,
  FadeContent,
} from "./style"

class BarnsMobileNav extends Component {
  render() {
    const { isVisible } = this.props
    return (
      <DropWrapp isVisible={isVisible}>
        <DropButton onClick={this.props.open} isVisible={isVisible}>
          <DropButtonText>Barns</DropButtonText>
          <DropButtonImg isVisible={isVisible}>
            <PlusIcon fill="#1E4156" />
          </DropButtonImg>
        </DropButton>
        <FadeContent isVisible={isVisible} onClick={this.props.onChange}>
          <Link to="/barns/vertical-roof/">Vertical Roof Barns</Link>
          <Link to="/barns/a-frame-roof/">A-Frame Roof Barns </Link>
          <Link to="/barns/regular-roof/">Regular Roof Barns</Link>
          <Link to="/barns/seneca-barns/">Seneca Barns </Link>
          <Link to="/barns/carolina-barns/">Carolina Barns </Link>
          <Link to="/metal-barn-prices/">Metal Barns Prices </Link>
          <Link to="/barns/metal-barn-kits/">Metal Barn Kits </Link>
          <RedLink to="/barns/">All Barns</RedLink>
        </FadeContent>
      </DropWrapp>
    )
  }
}

export default BarnsMobileNav
