import React, { Component } from "react"
import styled from "styled-components"
import axios from "axios"
import LazyLaod from "react-lazyload"

import { Link, navigate, StaticQuery, graphql } from "gatsby"
import ArrowIcon from "../Icons/ArrowIcon"
import PhoneIcon from "../Icons/PhoneIcon"
import MapPointer from "../Icons/MapPointer"
import PaperPlan from "../Icons/PaperPlan"
import FaceBook from "../Icons/FaceBook"
import Youtub from "../Icons/Youtub"
import { CustomValidationMessage, RequiredTriangle } from "../FormStyle"
import PrimaryButton from "../Button/PrimaryButton"
import GoogleMap from "../GoogleMap"
import Pinterest from "../Pinterest"
import Instagram from "../Instagram"
import { Container } from "../Section"
// import StateAccordion from "../StateAccordion"

const FooterWrap = styled.footer`
  color: white;
  padding: 30px 0px 60px;
  @media (min-width: 992px) {
    padding: 40px 0px 60px;
  }
  @media (min-width: 1200px) {
    padding: 60px 0px 60px;
  }
  @media (min-width: 1600px) {
    padding: 80px 0px 80px;
  }

  background: #1e4156;
  background: -moz-linear-gradient(
    -45deg,
    #1e4156 0%,
    #1e4156 50%,
    #062c44 50%,
    #062c44 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    #1e4156 0%,
    #1e4156 50%,
    #062c44 50%,
    #062c44 100%
  );
  background: linear-gradient(
    106deg,
    #1e4156 0%,
    #1e4156 50%,
    #062c44 50%,
    #062c44 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e4156', endColorstr='#062c44',GradientType=1 );

`
const FooterTop = styled.div`
  margin:0;
`
const MenuWrap = styled.div`
  display:flex;
  flex-wrap:wrap;
  margin:0 -15px;
`
const MenuLeft = styled.div`
  position:relative;
  padding:0 15px;
  width:100%;
  display:flex;
  flex-wrap: wrap;
  align-items:center;
  flex-direction:column;
  @media (min-width: 576px) {
    flex-direction:row;
  }
  @media (min-width: 992px) {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

`
const MenuRight = styled.div`
  position:relative;
  padding:0 15px;
  width:100%;
  @media (min-width: 992px) {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
`


const Address = styled.div`
  position:relative;
  width:100%;
  margin-bottom:0;
  
  @media (min-width: 576px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (min-width: 992px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding:0;
  }
`
const MapAddress = styled.div`
  margin-bottom:20px;
  position:relative;
  width:100%;
 @media (min-width: 576px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
   @media (min-width: 992px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding:0;
  }
`
const AddressList = styled.div`  
  margin-bottom:20px;
  
`
const AddresItem = styled.div` 
  margin-bottom:10px;
  display:flex;
  flex-wrap:wrap;
  .icon{
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    height:20px;
    + .text{
      margin-left:10px;
    }
  }
  .text{
    font-size: 15px;
    line-height: 24px;
    @media (min-width: 1200px) {
      font-size: 16px;
      line-height: 26px;
    }
  }
  & a {
    color: #fff;
    &:hover,
    &:focus {
      color: #ed602b;
    }
  }
`

const SocialNetwork = styled.div`
  display: flex;
  flex-wrap:wrap;
  margin:0 -5px 20px -5px;
  position: relative;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin:0 5px;
    width: 34px;
    height: 34px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 10px;    
    &:hover {
      background: #ed602b;
    }
  }

  .instagram,
  .pinterest {
    svg {
      width: 18px;
      height: 18px;
    }
  }
`


const NewsLetter = styled.div`  
  position:relative;
  z-index:2;
  margin-bottom:20px;
  max-width:300px;
  width:100%;
  form{
    margin-bottom:0;
  }
  .input-icon {
    > .form-control {
      padding: 8px 15px;
      @media (min-width: 1200px) {
        padding: 10px 15px;
      }
      @media (min-width: 1600px) {
        padding: 12px 15px;
      }
      border-color:#fff;
      border-radius: 30px;
      color: white;
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        transition: background-color 9999s ease-in-out 0s;
        -webkit-text-fill-color: #fff;
      }
      &::placeholder {
        color: #fff;
      }
      &:focus,
      &:active {
        &::placeholder {
          color: #fff;
        }
      }
    }
    .icon {
      width: 45px;
      > svg {
        fill: #fff;
        width: 25px;
      }
    }
    &.right {
      > .form-control {
        padding-right: 45px;
        padding-left: 15px;
      }
    }
  }
`

const MenuGrid = styled.div`
  position: relative;
  z-index: 2;
  width:100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin:0 -5px;
  @media (min-width: 992px) {
    flex-wrap: nowrap;
  }
`
const MenuItemGrid = styled.div`
  position:relative;
  width:100%;
  padding:0 5px;
  flex: 0 0 50%;
  max-width: 50%;
  @media (min-width: 768px) {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto;
  }
`

const MenuTitle = styled.h3`
  color: #fff;
`
const MenuList = styled.ul`
  list-style:none;
  margin:0 0 20px;
  padding:0;
`
const MenuItem = styled.li`
  color: #fff;    
  margin:0;
  padding:0;
  & a {
    display:inline-flex;
    font-size: 15px;
    line-height: 24px;
    @media (min-width: 1200px) {
      font-size: 16px;
      line-height: 26px;
    }
    color: #fff;
    &:hover,
    &:focus {
      color: #ed602b;
    }
  }
  + li{
    margin-top:10px;
  }
`

const CityWrap = styled.div`
`
const CityGrid = styled.ul`
  display: grid;
  z-index: 2;
  position: relative;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 26px;
  grid-template-columns: repeat(2, auto);
  @media (min-width: 768px) {
    grid-template-columns: repeat(4, auto);
  }
`
const CityItem = styled.li`
  margin-bottom: 10px;
  @media only screen and (max-width: 480px) {
    font-size: 14px;
    line-height: 20px;
  }
  & a {
    color: white;
    &:hover,
    &:focus {
      color: #ed602b;
    }
  }
`

const CopyRight = styled.div`
  z-index: 2;
  position: relative;
  width: 100%;
  text-align: center;
  display: block;
  font-size: 14px;
  color: #ccc;
`


const CustomRequired = styled(CustomValidationMessage)`
  width: 250px;
  margin-top: 1px;
  z-index: 2;
`

const FooterCta = styled.div`
  background-color: #fff;
  box-shadow: 0px -5px 25px 0px rgb(0 75 155 / 25%);
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  text-align: center;
  display: block;
  padding: 5px 15px;
  @media (min-width: 768px) {
    display: none;
  }
  a {
    display: block;
  }
  .btn {
    display: flex;
  }
`

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newsletter: "",
      newsletterRequire: false,
      validEmail: false,
    }
    this.handleNewsletter = this.handleNewsletter.bind(this)
    this.submitNewsletter = this.submitNewsletter.bind(this)
  }
  handleNewsletter(event) {
    const { value } = event.target
    this.setState({
      newsletter: value,
      newsletterRequire: false,
      validEmail: false,
    })
  }
  submitNewsletter(e) {
    e.preventDefault()
    const { location } = this.props
    if (this.state.newsletter === "") {
      this.setState({ newsletterRequire: true })
    } else if (this.state.newsletter.indexOf("@") === -1) {
      this.setState({ validEmail: true })
    } else {
      const data = {
        source: location.origin,
        form: location.pathname + "newsletter form",
        page: location.pathname,
        email: this.state.newsletter,
      }
      axios({
        method: "post",
        url: "https://crm.senseicrm.com/api/create-lead-wordpress",
        data: data,
        headers: {
          Authorization:
            "Bearer " +
            "13IMU4ZLF9WyrjRj6JuTfVRwGswQVmfBpO39w7bYvaFT93nbcTKE8PnUBv4bWMrM",
        },
      })
        .then(res => {
          navigate("/thank-you-newsletter/")
        })
        .catch(error => {
          alert(error)
        })
    }
  }
  render() {
    const { newsletterRequire, validEmail } = this.state
    return (
      <FooterWrap>
        <FooterTop>
          <Container>
            <MenuWrap>
              <MenuLeft>
                <Address>
                  <MenuTitle>ProBuilt Address</MenuTitle>
                  <AddressList>
                    <AddresItem>
                      <span className="icon"><MapPointer fill="white" /></span>
                      <span className="text"><a href="https://maps.app.goo.gl/ypmUL8Sg1Ta7ST3b7" target="_blank" rel="noreferrer" aria-label="maps">3752 W US Hwy 90, Lake City, Fl 32055</a></span>
                    </AddresItem>
                    <AddresItem>
                      <span className="icon"><PhoneIcon fill="white" /></span>
                      <span className="text"><a href="tel:8777541818">(877) 754-1818</a></span>
                    </AddresItem>
                    <AddresItem>
                      <span className="icon"><PaperPlan fill="white" /></span>
                      <span className="text"><a href="mailto:info@probuiltsteel.com">info@probuiltsteel.com</a></span>
                    </AddresItem>
                  </AddressList>
                  <SocialNetwork>
                    <a
                      href="https://www.youtube.com/channel/UCgX_B_Nfel_Z9Tbo1JLSCCA"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="youtube"
                    >
                      <Youtub fill="white" />
                    </a>
                    <a
                      href="https://www.facebook.com/ProBuiltSteelBuildings/"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="facebook"
                    >
                      <FaceBook fill="white" />
                    </a>
                    <a
                      href="https://www.instagram.com/probuiltsteelbuildings/"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="instagram"
                      className="instagram"
                    >
                      <Instagram fill="white" />
                    </a>
                    <a
                      href="https://www.pinterest.com/probuiltsteelbuildings/"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="pinterest"
                      className="pinterest"
                    >
                      <Pinterest fill="white" />
                    </a>
                  </SocialNetwork>

                  <NewsLetter>
                    <form onSubmit={this.submitNewsletter}>
                        <div className="input-icon right">
                          <input
                            type="email"
                            placeholder="Subscribe to our Newsletter"
                            name="newletter_email"
                            id="newletter_email"
                            onChange={this.handleNewsletter}
                            aria-label="newsletter email"
                            className="form-control"
                          />
                          <button type="submit" aria-label="subscribe" className="icon"><ArrowIcon fill="#FFFFFF" /></button>
                        </div>
                          <CustomRequired display={newsletterRequire ? "block" : "none"}><RequiredTriangle /><span>Please input your email address</span></CustomRequired>
                          <CustomRequired display={validEmail ? "block" : "none"}><RequiredTriangle /><span>Please input valid email address</span></CustomRequired>
                    </form>
                  </NewsLetter>  

                </Address>
                <MapAddress>
                  <LazyLaod><GoogleMap /></LazyLaod>
                </MapAddress>
              </MenuLeft>

              <MenuRight>
                <MenuGrid>
                  <MenuItemGrid>
                    <MenuTitle>Our Buildings</MenuTitle>
                    <MenuList>
                      <MenuItem><Link to="/carports/"> Carports</Link></MenuItem>
                      <MenuItem><Link to="/garages/"> Garages</Link></MenuItem>
                      <MenuItem><Link to="/barns/"> Barns</Link></MenuItem>
                      <MenuItem><Link to="/rv-covers/"> RV Covers</Link></MenuItem>
                      <MenuItem><Link to="/utility-buildings/"> Utility Buildings</Link></MenuItem>
                      <MenuItem><Link to="/storage-buildings/"> Storage Buildings</Link></MenuItem>
                      <MenuItem><Link to="/commercial-metal-buildings/">Commercial Buildings</Link></MenuItem>
                      <MenuItem><Link to="/metal-building-homes/"> Metal Building Homes</Link></MenuItem>
                    </MenuList>
                  </MenuItemGrid>

                  <MenuItemGrid>
                    <MenuTitle>ProBuilt Info</MenuTitle>
                    <MenuList>
                      <MenuItem><Link to="/about/"> About ProBuilt</Link></MenuItem>
                      <MenuItem><Link to="/service-area/"> Service Area</Link></MenuItem>
                      <MenuItem><Link to="/reviews/"> Reviews</Link></MenuItem>
                      <MenuItem><Link to="/faqs/"> Frequently Ask Questions</Link></MenuItem>
                      <MenuItem><Link to="/blog/"> Articles</Link></MenuItem>
                      <MenuItem><Link to="/contact-us/"> Contact us</Link></MenuItem>
                      <MenuItem><Link to="/infographics/"> Infographic</Link></MenuItem>
                    </MenuList>
                  </MenuItemGrid>

                  <MenuItemGrid>
                    <MenuTitle>Buying Guide</MenuTitle>
                    <MenuList>
                      <MenuItem><Link to="/buying-guide/steel-building-components/">Steel Building Components</Link></MenuItem>
                      <MenuItem><Link to="/buying-guide/metal-building-roof-guide/">Metal Building Roof Guide</Link></MenuItem>
                      <MenuItem><Link to="/buying-guide/site-preparation-guide/">Site Preparation Guide</Link></MenuItem>
                      <MenuItem><Link to="/buying-guide/order-installation-process/">Order/Installation Process</Link></MenuItem>
                      <MenuItem><Link to="/buying-guide/financing-process/">Financing Process</Link></MenuItem>
                      <MenuItem><Link to="/buying-guide/rto-process/"> RTO Process</Link></MenuItem>
                      <MenuItem><Link to="/buying-guide/permit-engineering-guide/">Permit & Engineering Guide</Link></MenuItem>
                      <MenuItem><Link to="/buying-guide/metal-building-certification/">Metal Building Certifications</Link></MenuItem>
                      <MenuItem><Link to="/buying-guide/metal-building-warranty/">Metal Building Warranty</Link></MenuItem>
                    </MenuList>
                  </MenuItemGrid>

                  <MenuItemGrid>
                    <MenuTitle>Other Links</MenuTitle>
                    <MenuList>
                      <MenuItem><Link to="/privacy-policy/">Privacy Policy</Link></MenuItem>
                      <MenuItem><Link to="/sitemap/"> Sitemap</Link></MenuItem>
                      <MenuItem><Link to="/case-studies/">Case Studies</Link></MenuItem>                      
                    </MenuList>
                    <MenuTitle>State</MenuTitle>
                    <MenuList>
                      <MenuItem><Link to="/steel-buildings-florida/">Steel Building FL</Link></MenuItem>
                    </MenuList>
                  </MenuItemGrid>

                </MenuGrid>
              </MenuRight>
            </MenuWrap>

            <CityWrap>
              <h3>Cities</h3>
              <CityGrid>
                <StaticQuery
                  query={graphql`
                    query CityLinksQuery {
                      cities: allContentfulCityLanding {
                        nodes {
                          url
                          cityName
                        }
                      }
                    }
                  `}
                  render={data => {
                    return data.cities.nodes.map((city, i) => {
                      return (
                        <CityItem key={i}>
                          <Link to={city.url}>{city.cityName}</Link>
                        </CityItem>
                      )
                    })
                  }}
                />
              </CityGrid>
            </CityWrap>
            {/* <StateAccordion /> */}
          </Container>
        </FooterTop>
        <CopyRight><Container>© {new Date().getFullYear()} ProBuilt Steel Buildings. All rights reserved.</Container></CopyRight>
        <FooterCta className="footer-cta"><a href="tel:8777541818"><PrimaryButton text="(877) 754-1818" icon={<PhoneIcon />} /></a></FooterCta>
      </FooterWrap>
    )
  }
}
export default Footer
