import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { RedLink } from "../../Link"
import ArrowIcon from "../../Icons/ArrowIcon"
import { Divider } from "../../Section"
import {
  SingleDropWrapper,
  DropItem,
  Triangle,
  dropdown,
} from "./tags-component"

const ButtomNav = styled.div`
  position: absolute;
  width: 710px;
  padding: 25px 70px;
  text-align: left;
  margin-left: -320px;
  top: 418px;
  background: #f6f7f8;
  border: 1px solid rgba(1, 9, 32, 0.1);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  animation: ${dropdown} ease 0.5s;
  & a{display: inline-block;}
  & svg {
    transform: none !important;
  }
`
class BuyingGuideDropdown extends React.Component {
  render() {
    return (
      <div>
        <SingleDropWrapper ml="-320px">
          <div style={{ width: "300px" }}>
            <Triangle left="115%" />
            <DropItem>
              <Link to="/buying-guide/steel-building-components/">
                Steel Building Components <ArrowIcon fill="#ED602B" />
              </Link>
            </DropItem>
            <Divider />
            <DropItem>
              <Link to="/buying-guide/metal-building-roof-guide/">
                Metal Building Roof Guide <ArrowIcon fill="#ED602B" />
              </Link>
            </DropItem>
            <Divider />
            <DropItem>
              <Link to="/buying-guide/order-installation-process/">
                Order/Installation Process <ArrowIcon fill="#ED602B" />
              </Link>
            </DropItem>
            <Divider />
            <DropItem>
              <Link to="/buying-guide/permit-engineering-guide/">
                Permit & Engineering Guide <ArrowIcon fill="#ED602B" />
              </Link>
            </DropItem>
          </div>
          <div style={{ width: "250px" }}>
            <DropItem>
              <Link to="/buying-guide/site-preparation-guide/">
                Site Preparation Guide <ArrowIcon fill="#ED602B" />
              </Link>
            </DropItem>
            <Divider />
            <DropItem>
              <Link to="/buying-guide/financing-process/">
                Financing Process <ArrowIcon fill="#ED602B" />
              </Link>
            </DropItem>
            <Divider />
            <DropItem>
              <Link to="/buying-guide/rto-process/">
                RTO Process <ArrowIcon fill="#ED602B" />
              </Link>
            </DropItem>
            <Divider />
          </div>
        </SingleDropWrapper>
        <ButtomNav>
          <RedLink to="/buying-guide/">
            Buying Guide <ArrowIcon fill="#ED602B" />
          </RedLink>
        </ButtomNav>
      </div>
    )
  }
}

export default BuyingGuideDropdown
