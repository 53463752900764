import React, { Fragment, useEffect, useRef, useState } from "react"
import {
  ProductItem,
  ProductList,
  ProductListWrapper,
  PSelectorModalBody,
  SearchInput,
  SearchWrap,
} from "./styles"
import {
  CloseButton,
  Modal,
  ModalTitle,
  ModalBackdrop,
  ModalContent,
  ModalDialog,
} from "../Modal"
import CloseIcon from "../Icons/CloseIcon"
import SearchIcon from "../Icons/SearchIcon"
import { useSearch } from "../../utils/useSearch"

const ProductSelectorModal = ({ isVisible, onSelect, onToggle }) => {
  const [search, setSearch] = useState("")
  const inputRef = useRef(null)
  const filteredProducts = useSearch(search, "product")

  useEffect(() => {
    if (isVisible) {
      inputRef.current.focus()
    } else {
      setSearch("")
    }
  }, [isVisible])

  return (
    <Fragment>
      <Modal
        className={"modal fade " + (isVisible ? "show" : "")}
        style={{ display: isVisible ? "block" : "none" }}
      >
        <ModalDialog className="modal-dialog modal-dialog-centered">
          <ModalContent className="modal-content">
            <PSelectorModalBody>
              <CloseButton className="close product-select-cancel" onClick={onToggle}><CloseIcon /></CloseButton>
              <ModalTitle className="modal-title">Add Your Building</ModalTitle>
              <SearchWrap>
                <SearchInput className="search">
                  <div className="input-icon right">
                    <input
                      ref={inputRef}
                      className="form-control"
                      type="text"
                      value={search}
                      placeholder="Type your keywords..."
                      onChange={e => setSearch(e.target.value)}
                    />
                    <span className="icon">
                      <SearchIcon />
                    </span>
                  </div>
                </SearchInput>
              </SearchWrap>
              <ProductListWrapper>
                <ProductList>
                  {filteredProducts.map((p, i) => (
                    <ProductItem
                      key={i}
                      onClick={() => {
                        onSelect(p)
                        setSearch("")
                      }}
                    >
                      <span>{p.title}</span>
                    </ProductItem>
                  ))}
                </ProductList>
              </ProductListWrapper>
            </PSelectorModalBody>
          </ModalContent>
        </ModalDialog>
      </Modal>
      {isVisible && (
        <ModalBackdrop className={"fade " + (isVisible ? "show" : "")} />
      )}
    </Fragment>
  )
}

export default ProductSelectorModal
