import styled from 'styled-components'

export const DropWrapp = styled.div`
	cursor: pointer;
	padding: 10px 15px;
	@media(max-width: 480px) {
		padding: 5px 15px;
	}
`
export const DropButton = styled.div`
	font-size: 20px;
	font-weight: 600;
	line-height: 29px;
	text-align: left;
	color: #484D4E;
	margin-top: 0;
	width: calc(100% - 40px);
	display: flex;
	line-height: 22px;
	align-items: center;
	@media(max-width: 480px) {
		font-size: 18px !important;
		line-height: 24px !important;
		width: 100%;
	}
`
export const DropButtonText = styled.div`
	width: calc(100% - 10px);
	display: inline-block;
	color: #062C44;
`
export const DropButtonImg = styled.div`
	width: 10px;
	display: inline-block;
	& svg {
		float: right;
		transform: ${props=>(props.isVisible ? "rotate(45deg)" : "rotate(90deg)")};
		transition: all .3s ease;
	}
`
export const FadeContent = styled.div`
	display: ${props=>(props.isVisible ? "block" : "none")};
	margin-left: 10px;
	padding-top: 15px;
	& a {
		text-decoration: none;
		display: block;
		color: #062C44;
		padding: 5px 0;
	}
	@media(max-width: 480px) {
		margin-top: 5px;
		padding-top: 5px;
		padding-bottom: 5px;
	}
`